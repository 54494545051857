import React from 'react'
import { MDBCol, MDBCard, MDBCardBody, MDBCardText } from 'mdbreact'
import { GatsbyImage } from "gatsby-plugin-image"
import Link from 'gatsby-link'


class Card extends React.Component {
  render() {
    const colHeight = this.props.height != null ? this.props.height : 'auto'
    return (
      <MDBCol
        lg={this.props.collg}
        md={this.props.colmd}
        className="pb-4 d-flex"
      >
        <MDBCard className="flex-fill">
          {this.props.link ? 
            <>
              {this.props.link.substring(0, 4) != 'http' && this.props.link.substring(0, 8) != '/static/' ? (
                <Link to={this.props.link}>
                  {this.props.image && (
                  <GatsbyImage
                      top="top"
                      image={this.props.image}
                      alt={this.props.imageAltText}
                      className="rounded-top"
                    />
                  )}
                </Link>
              ) : (
                <a href={this.props.link} target="_blank" rel="noopener">
                  {this.props.image && (
                    <GatsbyImage
                      top="top"
                      image={this.props.image}
                      alt={this.props.imageAltText}
                      className="rounded-top"
                    />
                  )}
                </a>
              )}
            </>
            : 
            <>
            {this.props.image && (
                    <GatsbyImage
                      top="top"
                      image={this.props.image}
                      alt={this.props.imageAltText}
                      className="rounded-top"
                    />
                  )}
            </>
          }

          <MDBCardBody>
            <div style={{ maxHeight: colHeight, minHeight: colHeight }}>
              <h3 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-medium mt-2">{this.props.title}</h3>
              {this.props.subtitle != '' && this.props.subtitle != null && (
                <h4 className="font-w-400 letter-spacing-1 text-xs-medium text-medium mt-2">{this.props.subtitle}</h4>
              )}
            </div>
            <hr />
            <MDBCardText className="font-w-400 text-medium mt-2 mb-2">
              {this.props.link && (
                <>
                  {this.props.link.substring(0, 4) != 'http' &&
                  this.props.link.substring(0, 8) != '/static/' ? (
                    <Link to={this.props.link} className="effect">
                      {this.props.description}
                    </Link>
                  ) : (
                    <a
                      href={this.props.link}
                      className="effect"
                      target="_blank"
                      rel="noopener"
                    >
                      {this.props.description}
                    </a>
                  )}
                </>
              )}
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    )
  }
}

export default Card